import { Observable } from 'rxjs';
import {
  RequestCreateDto,
  RequestDto,
  RequestEditDto,
  RequestPageQueryParamsDto,
  RequestPageDto,
  RequestShowDto,
  RequestNotificationBodyDto,
  ResponseNotificationDto,
  RequestEditStateDto,
} from '../dtos/request.dto';

export abstract class RequestRepository {
  // abstract getAll(params?: any): Observable<RequestGetAllDto>;
  abstract getPage(
    params: RequestPageQueryParamsDto
  ): Observable<RequestPageDto>;
  abstract create(user: RequestCreateDto): Observable<RequestDto>;
  abstract update(
    data: RequestEditDto,
    headers?: Record<string, string>
  ): Observable<RequestDto>;
  abstract updateState(userId: RequestEditStateDto): Observable<void>;
  abstract show(userId: number): Observable<RequestShowDto>;
  abstract notification(
    body: RequestNotificationBodyDto
  ): Observable<ResponseNotificationDto>;
  abstract delete(userId: number): Observable<void>;
}
